/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import {
  Button, Modal, Select, Input,
} from 'components/common';
import api from 'api/server';
import { millionsSeparator, numeralTextConverter } from 'helpers';

import formValidator from '../formValidator';
import * as styles from './AddBatchModal.styles';

const AddBatchModal = ({
  isModalOpen,
  setModal,
  setDataBatches,
  options,
  setPagination,
}) => {
  const modalRef = useRef(null);
  const [newBatch, setNewBatch] = useState({
    batchType: '',
    program: '',
    location: '',
    registrationStartDate: '',
    registrationEndDate: '',
    prepStartDate: '',
    prepEndDate: '',
    classStartDate: '',
    classEndDate: '',
    classPreparationStartDate: null,
    classPreparationEndDate: null,
    graduationDate: '',
    classDaySchedule: '',
    classTimeSchedule: '',
    // discountPrice: '',
    // discountEndDate: '',
    basePrice: '',
  });
  const [notif, setNotif] = useState({
    type: null,
    message: '',
  });
  const programOptions = options.programOptions.map((d) => ({
    value: d.id,
    label: d.label,
  }));

  const handleChange = (e) => {
    setNewBatch((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFindCode = () => {
    if (newBatch.program && newBatch.location) {
      const programCode = options.programOptions.find(({ id }) => id === +newBatch.program);
      const location = options.campusLocations.find(({ value }) => value === +newBatch.location);
      const similarProgramArr = options.batchOptions
        .filter(({ code }) => {
          if (code.includes(programCode.value) && code.includes(location.code)) return code;
          return '';
        });
      let latestNumber = 0;
      similarProgramArr.forEach((d) => {
        let number = d.code.split('-')[1];
        const firstDigit = number[0];
        const secondDigit = number[1];
        const thirdDigit = number[2];
        if (firstDigit === '0' && secondDigit === '0') {
          number = thirdDigit;
        }
        if (+number > +latestNumber) {
          latestNumber = number;
        }
      });
      latestNumber = Number(latestNumber);
      if (latestNumber < 10) {
        latestNumber = `00${latestNumber + 1}`;
      } else if (latestNumber > 10 && latestNumber < 100) {
        latestNumber = `0${latestNumber + 1}`;
      } else {
        latestNumber += 1;
      }
      return `${programCode.value}-${latestNumber}-${location.code}`;
    }
    return '';
  };

  const handleModifiedDatePayload = (date) => {
    if (date) {
      return `${date}T00:00:00Z`;
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNotif({ type: null, message: '' });
    try {
      const payload = {
        program_id: +newBatch.program,
        campus_location_id: +newBatch.location,
        batch_type_id: +newBatch.batchType,
        code: handleFindCode(),
        base_price: numeralTextConverter(newBatch.basePrice),
        // discount_price: +newBatch.discountPrice || null,
        // discount_end_date: handleModifiedDatePayload(newBatch.discountEndDate),
        registration_start_date: handleModifiedDatePayload(newBatch.registrationStartDate),
        registration_end_date: handleModifiedDatePayload(newBatch.registrationEndDate),
        class_preparation_start_date: handleModifiedDatePayload(newBatch.classPreparationStartDate),
        class_preparation_end_date: handleModifiedDatePayload(newBatch.classPreparationStartDate),
        class_start_date: handleModifiedDatePayload(newBatch.classStartDate),
        class_end_date: handleModifiedDatePayload(newBatch.classEndDate),
        graduation_date: newBatch.graduationDate
          ? handleModifiedDatePayload(newBatch.graduationDate) : null,
        schedule: JSON.stringify({
          day: newBatch.classDaySchedule,
          time: newBatch.classTimeSchedule,
        }),
      };
      const response = formValidator(payload);
      if (response !== 'valid') {
        throw response.message;
      }
      const { data } = await api.createBatch(payload);
      if (data.status) {
        const { data: batchesData } = await api.getAllBatches({ limit: 25 });
        setDataBatches(batchesData.data.results);
        setPagination({
          current: 1,
          total: batchesData.data.last_page || 1,
          totalData: batchesData.data.total,
        });
      }
      setModal(false);
    } catch (error) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setNotif({
        type: 'error',
        message: error.response?.data.errors[0].detail || error,
      });
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isModalOpen}
      setModal={setModal}
      closeModal={() => setModal(false)}
    >
      <div ref={modalRef}>
        <form onSubmit={handleSubmit}>
          {
            notif.type && (
              <span css={styles.notifStyles(notif.type)}>{notif.message}</span>
            )
          }
          <div css={styles.formRowStyles}>
            <Select
              placeholder="choose batch type"
              name="batchType"
              containerStyles={styles.selectContainerStyles}
              onChange={handleChange}
              options={options.batchTypeOptions}
              value={newBatch.batchType}
            />
          </div>
          <div css={styles.formRowStyles}>
            <Select
              placeholder="choose program"
              name="program"
              containerStyles={styles.selectContainerStyles}
              onChange={handleChange}
              options={programOptions}
              value={newBatch.program}
            />
          </div>
          <div css={styles.formRowStyles}>
            <Select
              placeholder="choose location"
              name="location"
              containerStyles={styles.selectContainerStyles}
              onChange={handleChange}
              options={options.campusLocations}
              value={newBatch.location}
            />
          </div>
          <div css={styles.formRowStyles}>
            <Input
              type="text"
              placeholder="batch number preview"
              name="batchPreview"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              disabled
              value={handleFindCode()}
            />
          </div>
          <div css={styles.halfFormRowStyles}>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>registration start date</label>
              <Input
                type="date"
                placeholder="registration start date"
                name="registrationStartDate"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.registrationStartDate}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>registration end date</label>
              <Input
                type="date"
                placeholder="batch number preview"
                name="registrationEndDate"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.registrationEndDate}
                min={newBatch.registrationStartDate}
              />
            </div>
          </div>
          <div css={styles.halfFormRowStyles}>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>class preparation start date</label>
              <Input
                type="date"
                name="classPreparationStartDate"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.classPreparationStartDate}
              />
            </div>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>class preparation end date</label>
              <Input
                type="date"
                name="classPreparationEndDate"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.classPreparationEndDate}
                min={newBatch.classPreparationStartDate}
              />
            </div>
          </div>
          <div css={styles.halfFormRowStyles}>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>class start date</label>
              <Input
                type="date"
                placeholder="batch number preview"
                name="classStartDate"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.classStartDate}
              />
            </div>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>class end date</label>
              <Input
                type="date"
                placeholder="batch number preview"
                name="classEndDate"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.classEndDate}
                min={newBatch.classStartDate}
              />
            </div>
          </div>
          <div css={styles.halfFormRowStyles}>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>class day schedule</label>
              <Input
                type="text"
                placeholder="Senin - Jumat"
                name="classDaySchedule"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.classDaySchedule}
              />
            </div>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>class time schedule</label>
              <Input
                type="text"
                placeholder="08:00 - 17:00"
                name="classTimeSchedule"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.classTimeSchedule}
              />
            </div>
          </div>
          <div css={styles.formRowStyles}>
            <label>graduation date</label>
            <Input
              type="date"
              placeholder="batch number preview"
              name="graduationDate"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={newBatch.graduationDate}
              min={newBatch.classEndDate}
            />
          </div>
          {/* <div css={styles.halfFormRowStyles}>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>discount price</label>
              <Input
                type="number"
                placeholder="batch discount price"
                name="discountPrice"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.discountPrice}
              />
            </div>
            <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
              <label>discount end date</label>
              <Input
                type="date"
                name="discountEndDate"
                containerStyles={styles.selectContainerStyles}
                styles={styles.inputStyles}
                onChange={handleChange}
                value={newBatch.discountEndDate}
              />
            </div>
          </div> */}
          <div css={styles.formRowStyles}>
            <label>base price</label>
            <Input
              type="type"
              placeholder="IDR 30.000.000"
              name="basePrice"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={millionsSeparator(newBatch.basePrice)}
            />
          </div>
          <Button
            styles={styles.buttonSubmitStyles}
            type="submit"
            onClick={handleSubmit}
          >
            submit
          </Button>
        </form>
      </div>
    </Modal>
  );
};

AddBatchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  setDataBatches: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  options: PropTypes.shape({
    batchOptions: PropTypes.arrayOf(PropTypes.shape({})),
    batchTypeOptions: PropTypes.arrayOf(PropTypes.shape({})),
    campusLocations: PropTypes.arrayOf(PropTypes.shape({})),
    programOptions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default AddBatchModal;
